<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mt-4">
              <div class="col-sm-12 col-md-4" style="margin-bottom: 15px;" v-if="this.$storageData.login_type == 1">
                <div role="group" class="btn-group">
                  <button
                      type="button"
                      class="btn btn-themeBrown"
                      @click="downloadSample()"
                  >
                    Excel
                  </button>
                </div>
              </div>
              <div class="col-sm-12 col-md-4">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show &nbsp;&nbsp;
                    <b-form-select
                        style="margin-left: 5px; margin-right: 5px"
                        v-model="perPage"
                        size="sm"
                        :options="pageOptions"
                    ></b-form-select
                    >&nbsp;&nbsp;&nbsp; entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-4 row">
                <div
                    id="tickets-table_filter"
                    class="dataTables_filter text-md-end"
                >
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                        v-model="filter"
                        type="search"
                        placeholder="Search..."
                        class="form-control form-control-sm ms-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                  striped
                  hover
                  outlined
                  bordered
                  :items="tableData"
                  :fields="fields"
                  responsive="sm"
                  :per-page="perPage"
                  :current-page="currentPage"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  @filtered="onFiltered"
              >
                <template v-slot:cell(Action)="data">
                  <!-- <i class="uil uil-edit-alt" title="Edit" style="font-size: 19px;"></i>
                  <i class="mdi mdi-delete" title="Delete" style="font-size: 19px;"></i> -->

                  <router-link
                      :to="{
                      name: 'corporateForm',
                      params: { id: data.item.corporateID, type: 'Edit' },
                    }"
                      v-if="$can('edit corporate')"
                  >
                    <i
                        class="uil uil-edit-alt"
                        title="Edit"
                        style="font-size: 19px"
                    ></i
                    ></router-link>

                  <router-link
                      :to="{
                      name: 'corporateDetails',
                      params: { id: data.item.corporateID, type: 'view' },
                    }"
                  v-if="$can('view corporate')">
                    <i
                        class="uil-info-circle"
                        title="View"
                        style="font-size: 19px; margin-left: 10px"
                    ></i
                    ></router-link>
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div
                    class="dataTables_paginate paging_simple_numbers float-end"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                        v-model="currentPage"
                        :total-rows="rows"
                        :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
Vue.use(VueAxios, axios);

export default {
  page: {
    title: "Corporate Table",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      items11: [
        {
          text: "This is <i>escaped</i> content",
          html: 'This is <i>raw <strong>HTML</strong></i> <span style="color:red">content</span>',
        },
      ],
      tableData: [],
      title: "Corporates",
      items: [
        {
          text: "View",
        },
        {
          text: "Corporate",
          active: true,
        },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100, 500, 1000, 2000, "All"],
      filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      fields: [
        {
          key: "Action",
          sortable: false,
        },
        {
          key: "corporateName",
          sortable: true,
        },
        {
          key: "corporateCode",
          sortable: true,
          tdClass: "align-right",
        },
        {
          key: "created",
          sortable: true,
        },
      ],
      path: "",
      editRight: "",
      deleteRight: "",
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
    this.readRestaurantData();
    this.checkUserRights();
  },
  methods: {
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    
    checkUserRights() {
      this.path = this.$route.path;
      this.axios
        .post(this.$loggedRole+"/checkUserRights", {
          empID: this.$storageData.profile.pid,
          empTypeID: this.$storageData.profile.empTypeID,
          path: this.path,
        })
        .then((result) => {
          this.editRight = result.data.data[0].isEdit;
          this.deleteRight = result.data.data[0].isDelete;
        });
    },

    downloadSample() {
      this.axios
        .post(this.$loggedRole+"/exportExcel",
          {
            exportData: this.tableData,
            page: "Corporates",
          },
          {
            responseType: "blob",
          }
        )
        .then((response) => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: "application/vnd.ms-excel",
            })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "corporates.xlsx");
          document.body.appendChild(link);
          link.click();
        });
    },

    readRestaurantData() {
      axios.get(this.$loggedRole+"/viewAllCorporates").then((response) => {
        //Then injecting the result to datatable parameters.

        this.tableData = response.data.data;
        //console.log((response));
      });
    },
  },
  middleware: "authentication",
};
</script>
